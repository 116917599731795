<template>
  <div
    id="safeCode"
    class="safeCode"
    :class="codeState !== 0 ? 'safeCode2' : ''"
  >
    <svg-icon class="logo" iconClass="safeCode_logo"></svg-icon>
    <div class="s_block">
      <div class="block_check" v-if="codeState === 0">
        <div class="sb-input sb-btn">
          <svg-icon
            class="svg_search"
            iconClass="safeCode_search"
            v-if="codeState !== 0"
          ></svg-icon>

          <!-- <input type="text" placeholder="请输入产品上的10位防伪号码" /> -->
          <van-field
            v-model="code"
            type="digit"
            maxlength="8"
            style="background:#f4f4f4"
            placeholder="请输入产品上的8位防伪号码"
          />
        </div>
        <template>
          <div class="sb-btn sb-check" v-if="code.length != 8">
            查询
          </div>
          <div
            class="sb-btn sb-check sb-check-yes"
            v-else
            @click="getCheckCode"
          >
            查询
          </div>
        </template>

        <div class="s_dashed"></div>
        <p class="s_demo_text">产品防伪号码示例</p>
        <img src="@image/safeCode/safeCode_demo.png" class="svg_code" />

        <!-- <p class="svg_number">
          <i>1</i>
          <i>9</i>
          <i>1</i>
          <i>8</i>
          <i>0</i>
          <i>0</i>
          <i>0</i>
          <i>1</i>
          <i>9</i>
          <i>1</i>
          <i>8</i>
        </p> -->
      </div>
      <div class="block_result" v-if="codeState === 1">
        <div class="img_solid">
          <img :src="after_goods.image" />
          <svg-icon class="svg_g" iconClass="safeCode_qualityGoods"></svg-icon>
        </div>

        <h1>{{ after_goods.goods_name }}</h1>
        <p>防伪号码:{{ code }}</p>
        <!-- <span
          @click="
            $router.push({
              name: 'HomeGoodsdetail',
              query: { goods_id: after_goods.goods_id },
            })
          "
          >商品详情</span
        > -->
      </div>

      <div class="block_error" v-if="codeState === 2">
        <svg-icon class="svg-iamgtip" iconClass="safeCode_box"></svg-icon>

        <h1>
          查询出错
        </h1>
        <p>找不到该防伪号码</p>
        <span
          @click="
            codeState = 0;
            code = '';
          "
          >重新输入</span
        >
      </div>
    </div>
    <div
      class="block_result-scanData"
      v-if="codeState === 1 && after_goods.check_log"
    >
      <i>该商品被用户扫码{{ after_goods.check_log.length }}次</i>
      <p v-for="(item, index) in after_goods.check_log" :key="index">
        {{ item.time | time }}
      </p>
    </div>
  </div>
</template>

<script>
import { Field } from "vant";
import { post } from "@get/http";
import { getQueryVariable } from "@get/util";
export default {
  components: {
    vanField: Field,
  },
  async created() {
    let code = getQueryVariable("code") || "";
    if (code) {
      let goods = await post("/lw.SellerAntiFake/getInfo", {
        data: {
          code,
        },
      });

      this.before_goods = goods || {};
    }
  },
  data() {
    return {
      codeState: 0,
      code: "",
      before_goods: null,
      after_goods: null,
    };
  },
  methods: {
    async getCheckCode() {
      let goods = await post(
        "/lw.SellerAntiFake/check",
        {
          data: {
            id: this.before_goods?.id,
            code: this.code,
          },
        },
        {
          result: true,
          resultKey: "code",
        }
      );
      if (goods.code == 10000) {
        this.codeState = 1;
        this.after_goods = goods.result;
      } else {
        this.codeState = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#safeCode {
  position: relative;
  .logo {
    position: absolute;
    right: rem(30);
    top: rem(40);
    width: rem(60);
    height: rem(60);
  }
}
.safeCode {
  width: 100%;
  height: 100%;
  background: url("~@image/safeCode/safeCode_bg1.jpg") no-repeat;
  background-size: 100%;
  padding-top: rem(390);
}
.safeCode2 {
  padding-top: rem(200);
  background: url("~@image/safeCode/safeCode_bg2.png") no-repeat;
  background-size: 100%;
}

.s_dashed {
  border-bottom: rem(2) dashed #e1e1e1;
  height: rem(80);
  box-sizing: border-box;
}
.s_block {
  width: rem(690);
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px rem(4) rem(16) rgba(0, 0, 0, 0.06);
  border-radius: rem(24);

  padding-top: rem(60);
  .sb-btn {
    display: flex;
    width: rem(570);
    align-items: center;
    height: rem(90);
    border-radius: rem(90/2);
    background: #f4f4f4;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: rem(28);
  }
  .sb-input {
    padding: 0 rem(38);
    margin-bottom: rem(40);

    .svg_search {
      width: rem(37);
      height: rem(37);
    }
    input {
      width: rem(370);
      color: #333;
      background: #f4f4f4;
      &::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #cccccc;
      }
      &::input-placeholder {
        /* placeholder颜色  */
        color: #cccccc;
      }
    }
  }
  .sb-check {
    text-align: center;
    color: #ccc;
    justify-content: center;
  }
  .sb-check-yes {
    background: #ff4848;
    color: #fff;
  }

  .s_demo_text {
    color: #999999;
    font-size: rem(26);
    text-align: center;
    padding: rem(40) 0 rem(30);
  }
  .svg_code {
    display: block;
    width: rem(294);
    height: rem(221);
    margin: 0 auto;
    padding-bottom: rem(50);
  }
  .svg_number {
    width: rem(294);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    color: #333;
    font-size: rem(28);
    padding-bottom: rem(68);
  }
}
.block_result-scanData {
  box-sizing: border-box;
  padding: 0 rem(30);
  > i {
    color: #999;
    font-size: rem(28);
    display: block;
    text-align: center;
    padding: rem(30) 0;
  }
  > p {
    background: url("~@image/safeCode/safeCode-time.png") no-repeat center left;
    background-size: rem(24);
    font-size: rem(28);
    color: #bababa;
    text-indent: rem(30);
    height: rem(40);
    margin-bottom: rem(20);
  }
}
.block_result {
  padding-bottom: rem(100);
  .img_solid {
    width: rem(280);
    height: rem(280);
    margin: 0 auto;
    position: relative;
    > img {
      width: rem(280);
      display: block;
      margin: 0 auto;
    }
    > .svg_g {
      position: absolute;
      width: rem(94);
      height: rem(94);
      bottom: 0;
      right: 0;
    }
  }

  > p,
  > span {
    color: #666666;
    font-size: rem(28);
    text-align: center;
    display: block;
  }
  > span {
    background: url("~@image/safeCode/safeCode-more.png") no-repeat center right;
    background-size: rem(20);
    width: rem(150);
    margin: rem(10) auto 0;
  }
  > h1 {
    color: #333333;
    font-weight: bold;
    text-align: center;
    font-size: rem(38);
    padding: rem(30) rem(92) rem(20);
  }
}
.block_error {
  .svg-iamgtip {
    width: rem(200);
    height: rem(214);
    display: block;
    margin: 0 auto rem(40);
  }
  > h1,
  > p {
    text-align: center;
  }
  > h1 {
    margin-bottom: rem(6);
    font-weight: bold;
    font-size: rem(38);
    color: #333;
  }
  > p {
    color: #999;
    font-size: rem(28);
    padding-bottom: rem(186);
  }
  > span {
    display: block;
    width: rem(130);
    background: url("~@image/safeCode/safeCode-more2.png") no-repeat center
      right;
    background-size: rem(20);
    font-size: rem(28);
    color: #206fe6;
    margin: 0 auto;
  }
  padding-bottom: rem(110);
}
</style>
